import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Input, Row } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { CLEAR_EXAMINATIONS, CLEAR_PATIENT_DETAILS, CLEAR_PERSONAL_REFERRAL_DETAILS, CLEAR_REFERRAL_REASONS, CLOSE_MODAL, OPEN_MODAL, SET_AUTH_USER, SET_EXAMINATION, SET_EXAMINATIONS, SET_LAST_EXAMINATION, SET_RND } from '../../redux/actions/types';
import Table from 'react-bootstrap/Table';
import { getCervixExaminations, getUserDetails } from '../../redux/actions/data.action';
import moment from 'moment';
import Logo from '../../assets/logo.png';
import { createUpdateUser, getAuthUser, getUserAccessTypes } from 'redux/actions/auth.action';
import { ManagementOptionsLeft, removeUserSession, InitUserData } from '../../Utils/commonData';
import { env_vars } from 'Utils/config';
import UploadButton from 'component/UploadButton';
import { fileUpload } from 'redux/actions/file.action';


const UserProfile = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user, isAuthenticated } = useSelector(({ auth }) => auth)
    const [values, setValues] = useState(InitUserData);
    const [users, setUsers] = useState([])
    const [accesses, setAccesses] = useState([])
    const [imageFile, setImageFile] = useState(null)
    const [imageUrl, setImageUrl] = useState('/static/default.png')
    const [rnd, setRnd] = useState(0);

    const handleExit = () => {


        navigate(-1)
    }


    const handleGetUsers = () => {

        dispatch(getUserDetails())
            .then(res => {
                setRnd(Math.random())
                if (res) {
                    setUsers(res)

                }
                setRnd(Math.random())
            })
    }

    const getAccessTypes = () => {

        dispatch(getUserAccessTypes())
            .then(res => {
                console.log('Access', res)
                if (res) {
                    setAccesses(res.data)
                }
                setRnd(Math.random())
            })
    }


    const handleSubmit = (e) => {
        e.preventDefault();

        if (imageFile) {
            const formData = new FormData();
            formData.append('file', imageFile, user.UserID);

            dispatch(fileUpload(formData, '/Profiles'))
                .then((res) => {
                    dispatch(createUpdateUser(values))
                        .then((resp) => {
                            console.log('RESP USER', resp)
                            // handleGetAuth()
                            let { user: userData } = resp.data;

                            dispatch({ type: SET_AUTH_USER, payload: { ...userData, imgUrl: imageUrl } })
                            dispatch({ type: SET_RND })
                            navigate('/dashboard')
                            // getItems(e.destinationDirectory)
                        })
                        .catch(err => {
                            console.log(err)
                        })
                    // dispatch({ type: SET_AUTH_USER, payload: { ...user, imgUrl: `/static/default.png` } });
                })
                .catch(err => {
                    console.log(err)
                })
        } else {



            dispatch(createUpdateUser(values))
                .then((resp) => {
                    let { user: userData } = resp.data;
                    console.log('RESP USER', resp)
                    dispatch({ type: SET_AUTH_USER, payload: { ...userData, imgUrl: imageUrl } })
                    dispatch({ type: SET_RND })
                    navigate('/dashboard')

                    // getItems(e.destinationDirectory)
                })
                .catch(err => {
                    console.log(err)
                })
        }


    }


    const handleChanges = prop => event => {
        setValues({ ...values, [prop]: event.target.value })
    }

    const handleImage = (url, file) => {
        setImageFile(file)
        setImageUrl(url)

    }



    useEffect(() => {
        handleGetUsers()
        getAccessTypes()
    }, [])


    useEffect(() => {
        setValues(user);
        setImageUrl(user.imgUrl)
    }, [user])

    console.log(imageUrl)

    return (
        <>
            <div className="card-header bg-iri w-100">
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'flex-end',
                        paddingTop: '5px'
                    }}
                >
                    <h4 className="text-white">User Profile</h4>
                </div>
            </div>
            <div className="card-body">
                <form onSubmit={handleSubmit} style={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'space-between', justifyContent: 'space-between' }}>
                    <Row>
                        <Col xs="12" sm="4" className='mt-3'>

                            <div className=' p-2'>
                                <div className='logoProfile'>
                                    <img src={`${env_vars.api_url}${imageUrl}`} alt="user-img" className="img-circle"
                                        width={250}
                                        height={250}
                                    />
                                </div>
                                <UploadButton
                                    setImageFile={handleImage}
                                />
                            </div>
                        </Col>
                        <Col xs="12" sm="4" className='mt-3'>

                            <div className='bordered p-2'>

                                <div className='row mt-2 pl-2 pr-2'>
                                    <div className='col-md-6 text-left'>
                                        <label className="control-label text-left text-blue">Title</label>
                                        <Input
                                            className='form-control'
                                            value={values.Title}
                                            onChange={handleChanges('Title')}
                                        />
                                    </div>
                                    <div className='col-md-6 text-left'>
                                        <label className="control-label text-left text-blue">ID</label>
                                        <Input
                                            className='form-control'
                                            value={values.ID}
                                            onChange={handleChanges('ID')}
                                        />
                                    </div>
                                </div>
                                <div className='row pl-3 pr-3 mt-2'>
                                    <label className="control-label text-left text-blue">Name</label>
                                    <Input
                                        className='form-control'
                                        value={values.Name}
                                        onChange={handleChanges('Name')}
                                    />

                                </div>
                                <div className='row  pl-3 pr-3 mt-2'>
                                    <label className="control-label text-left text-blue">Quals</label>
                                    <Input
                                        className='form-control'
                                        value={values.Qualifications}
                                        onChange={handleChanges('Qualifications')}
                                    />
                                </div>
                                <div className='row centered  pl-3 pr-3 mt-2'>
                                    <label className="control-label text-left text-blue">Post</label>
                                    <Input
                                        className='form-control'
                                        value={values.Post}
                                        onChange={handleChanges('Post')}
                                    />
                                </div>
                            </div>



                        </Col>
                        <Col xs="12" sm="4" className='mt-3'>
                            <div className='bordered p-2'>
                                <div className='row centered  pl-3 pr-3 mt-2'>
                                    <label className="control-label text-left text-blue">Password</label>
                                    <Input
                                        type="password"
                                        className='form-control'
                                        value={values.Password}
                                        placeholder='******'
                                        onChange={handleChanges('Password')}
                                    />
                                </div>
                                <div className='row centered pl-3 pr-3 mt-2'>
                                    <label className="control-label text-left text-blue">Trainer</label>
                                    <Input
                                        type="select"
                                        className="form-control"
                                        value={values.iUserID}
                                        onChange={(e) => {
                                            let user = users.find(a => a.iUserID == e.target.value);
                                            if (user) {
                                                setValues(user)
                                            } else {
                                                setValues({})
                                            }

                                        }
                                        }
                                    >
                                        <option value={null}>Select</option>
                                    </Input>
                                </div>

                                <div className='row centered  pl-3 pr-3 mt-2'>
                                    <label className="control-label text-left text-blue">Access</label>
                                    <Input
                                        disabled
                                        type="select"
                                        className="form-control"
                                        value={values.Access}
                                    // onChange={handleChanges('Access')}
                                    >
                                        <option className='form-control' value={null}>Select</option>
                                        {accesses.length !== 0 && accesses.map((a, index) => {
                                            return (
                                                <option key={index} className='form-control' value={a.iRef}>{a.sUserAccessLevel}</option>
                                            )
                                        })}
                                    </Input>
                                </div>
                                <div className='row centered  pl-3 pr-3 mt-2'>
                                    <label className="control-label text-left text-blue">Site</label>
                                    <Input
                                        className='form-control'
                                        value={values.SiteID}
                                    // onChange={handleChanges('SiteID')}
                                    />
                                </div>

                            </div>
                        </Col>
                    </Row>
                    <div className='mt-2' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div>   </div>
                        <div className='d-flex'>

                            < Button onClick={() => handleExit()} className='btn color-black' style={{ width: '75px' }} >Cancel</Button>
                            &nbsp;&nbsp;&nbsp;< Button className='btn color-black mr-2 btnIri' style={{ width: '75px' }}
                                type="submit"
                            >Save</Button>
                        </div>
                    </div>
                </form>

            </div>
        </>
    )
}

export default UserProfile;
