export const env_vars = {
    // api_url: `http://localhost:4000/api`,
    
    api_url: 'https://site1.compuscope.cloud/api',
    // api_url: 'https://irisoft-webapp02.azurewebsites.net/api',
    
    editor_api: 'https://docs.bugtech.online/example',
    rtmp_url: 'https://sharewin.pro'
}

