import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { baseToFormData } from 'Utils/helpers';
import { env_vars } from "Utils/config";
import { fileUpload, fileUploadTmp } from 'redux/actions/file.action';


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default function UploadButton({ setImageFile }) {
    const dispatch = useDispatch();
    const { user } = useSelector(({ auth }) => auth)

    const handleUpload = async (e) => {
        let file = e.target.files[0];
        const fileExtension = file.name.split(".").at(-1);


        const formData = new FormData();
        formData.append('file', file);
        dispatch(fileUploadTmp(formData, '/Profiles'))
            .then((res) => {
                setImageFile(`/files/download/tmpFile?filePath=/Profiles/${res.filename}`, file)
                console.log(res, 'res')
                // getItems(e.destinationDirectory)
            })
            .catch(err => {
                console.log(err)
            })
    }




    return (
        <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<ImageOutlinedIcon />}
        >
            Change Image
            <VisuallyHiddenInput type="file" accept="image/png, image/gif, image/jpeg" onChange={handleUpload} />
        </Button>
    );
}
