export const getUser = () => {
    const userStr = sessionStorage.getItem('user');
    if (userStr) return JSON.parse(userStr)
    else return null;
}

export const getToken = () => {
    return localStorage.getItem('token') || null
}

export const setUserSession = (token, user) => {
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user))
}

export const removeUserSession = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("user");
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    localStorage.removeItem('formDetails')
}

export const checkAuth = (err) => {
    if (err && err.response && err.response.status !== 401) return true;
    else return false;
}

export const maritalStatusData = [
    {
        title: 'Single',
        value: 'Single'
    },
    {
        title: 'Married',
        value: 'Married'
    },
    {
        title: 'Separated',
        value: 'Separated'
    },
    {
        title: 'Divorced',
        value: 'Divorced'
    },
    {
        title: 'Co-habiting',
        value: 'Co-habiting'
    },
    {
        title: 'Not known',
        value: 'Not known'
    }
]


export const ethnicOriginData = [
    {
        title: 'British (A)',
        value: 'British (A)',
        group: 'White',
        index: 1
    },
    {
        title: 'Irish (B)',
        value: 'Irish (B)',
        group: 'White',
        index: 1
    },
    {
        title: 'Any other White background (C)',
        value: 'Any other White background (C)',
        group: 'White',
        index: 1
    },
    {
        title: 'White and Black Caribbean (D)',
        value: 'White and Black Caribbean (D)',
        group: 'Mixed',
        index: 2
    },
    {
        title: 'White and Black Caribbean (E)',
        value: 'White and Black African (E)',
        group: 'Mixed',
        index: 2
    },
    {
        title: 'White and Asian (F)',
        value: 'White and Asian (F)',
        group: 'Mixed',
        index: 2
    },
    {
        title: 'Any other mixed background (G)',
        value: 'Any other mixed background (G)',
        group: 'Mixed',
        index: 2
    },
    {
        title: 'Chinese (R)',
        value: 'Chinese (R)',
        group: 'Other Ethnic Groups',
        index: 5
    },
    {
        title: 'Any other ethnic group (S)',
        value: 'Any other ethnic group (S)',
        group: 'Other Ethnic Groups',
        index: 5
    },
    {
        title: 'Caribbean (M)',
        value: 'Caribbean (M)',
        group: 'Black or Black British',
        index: 4
    },
    {
        title: 'African (N)',
        value: 'African (N)',
        group: 'Black or Black British',
        index: 4
    },
    {
        title: 'Any other Black background (P)',
        value: 'Any other Black background (P)',
        group: 'Black or Black British',
        index: 4
    },
    {
        title: 'Indian (H)',
        value: 'Indian (H)',
        group: 'Asian or Asian British',
        index: 3
    },
    {
        title: 'Pakistani (J)',
        value: 'Pakistani (J)',
        group: 'Asian or Asian British',
        index: 3
    },
    {
        title: 'Bangladeshi (K)',
        value: 'Bangladeshi (K)',
        group: 'Asian or Asian British',
        index: 3
    },
    {
        title: 'Any other Asian background (L)',
        value: 'Any other Asian background (L)',
        group: 'Asian or Asian British',
        index: 5
    },
    {
        title: 'Not stated (Z)',
        value: 'Not stated (Z)',
        group: 'Not Stated',
        index: 6
    },
]

export const traceResultHeader = [
    {
        title: "Last Name",
        value: "Surname"
    },
    {
        title: "First Name",
        value: "Forename"
    },
    {
        title: "Date of Birth",
        value: "DateOfBirth"
    }
]


export const defaultPatientData = {
    "patient": {
        "NHSnumber": "4637646345",
        "HospitalNumber": "420",
        "Forename": "John benedict",
        "Surname": "Geli",
        "MaidenName": "Bermoy",
        "DateOfBirth": "1997-08-21",
        "Address": "Tacloban City",
        "EthnicOrigin": "Pakistani (J)",
        "Occupation": "awdawdaw",
        "PartnerOccupation": "Stevens",
        "PhoneNumber": "231231",
        "MobilePhoneNumber": "32422343",
        "OtherPhoneNumber": "3423423424"
    },
    "referral": {
        "ReferringCentre": "F.P. Clinic Direct",
        "ReferrerNameCervix": "Stevenson",
        "ReferrerAddressCervix": "Pearleas",
        "GPName": "Gp Name Test",
        "GPAddress": "Gp Name Address",
        "GPPhoneNumber": "231231313221"
    }
}

export const stdData = [
    'BV', 'Candida', 'Chlamidia', 'Genital Warts', 'Gonorrhoea', 'Herpes', 'HPV', 'PID', 'Syphilis', 'TV'
]


export const cancelReasonsData = [
    {
        key: "DNA",
        value: "DNA no advance warning",
        id: 1
    },
    {
        key: "DNA",
        value: "DNA arrived late",
        id: 3
    },
    {
        key: "DNA",
        value: "DNA left without being seen",
        id: 2
    },
    {
        key: "P Cancel",
        value: "Cancelled by patient in advance",
        id: 4
    },
    {
        key: "P Cancel",
        value: "Cancelled by patient on the day",
        id: 5
    },
    {
        key: "H Cancel",
        value: "Cancelled by clinic",
        id: 6
    }
]

export const letterTemplates = [
    {
        title: "First Appointment",
        type: "Patient",
        align: "left"
    },
    {
        title: "First Appointment - Treatment",
        type: "Patient",
        align: "left"
    },
    {
        title: "Hospital Cancel & Re-book",
        type: "Patient",
        align: "left"
    },
    {
        title: "Patient Cancel & Re-book",
        type: "Patient",
        align: "left"
    },
    {
        title: "DNA",
        type: "Patient",
        align: "left"
    },
    {
        title: "Reminder",
        type: "Patient",
        align: "left"
    },
    {
        title: "Discharge",
        type: "Patient",
        align: "left"
    },
    {
        title: "Free Text",
        type: "Patient",
        align: "right"
    },
    {
        title: "Negative Colposcopy > NSC",
        type: "Patient",
        align: "right"
    },
    {
        title: "Results & Re-colp",
        type: "Patient",
        align: "right"
    },
    {
        title: "Results & Treatment",
        type: "Patient",
        align: "right"
    },
    {
        title: "Treatment Results",
        type: "Patient",
        align: "right"
    },
    {
        title: `|`,
        type: "Patient",
        align: "right"
    },
    {
        title: "Post-Treatment Checkup",
        type: "Patient",
        align: "right"
    },
    {
        title: "First Examination",
        type: "GP",
        align: "left"
    },
    {
        title: "See & Treat",
        type: "GP",
        align: "left"
    },
    {
        title: "Results & Management",
        type: "GP",
        align: "left"
    },
    {
        title: "Follow-up Examination",
        type: "GP",
        align: "left"
    },
    {
        title: "Discharge",
        type: "GP",
        align: "left"
    },
    {
        title: "Free Text",
        type: "GP",
        align: "right"
    },
    {
        title: "Results & Treatment",
        type: "GP",
        align: "right"
    },
    {
        title: "Treatment Done",
        type: "GP",
        align: "right"
    },
    {
        title: "Treatment Results",
        type: "GP",
        align: "right"
    }
]



export const referralReasonLetterData = [
    {
        key: "Negative (Normal)",
        value: "with a smear showing negative cytology"
    },
    {
        key: "PBC",
        value: "with PBC"
    },
    {
        key: "Borderline ?High Grade",
        value: "Borderline ?High Grade"
    },
    {
        key: "Contact bleeding",
        value: "with contact bleeding"
    },
    {
        key: "Abnormal screening cytology",
        value: "Abnormal screening cytology"
    },
    {
        key: "Suspicious symptoms other",
        value: "with other suspicious symptoms"
    },
    {
        key: "Borderline Change",
        value: "Borderline Change"
    },
    {
        key: "Glandular Neoplasia",
        value: "with a smear showing glandular neoplasia"
    },
    {
        key: "Borderline Squamous",
        value: "with a smear showing borderline squamous"
    },
    {
        key: "Borderline Endo Cervical",
        value: "Borderline Endo Cervical"
    },
    {
        key: "Borderline Nuc. Abn.",
        value: "with a smear showing borderline nuc. abn."
    },
    {
        key: "Mild Dyskaryosis",
        value: "with a smear showing mild dyskaryosis"
    },
    {
        key: "Moderate Dyskaryosis",
        value: "with a smear showing moderate dyskaryosis"
    },
    {
        key: "Severe Dyskaryosis",
        value: "with a smear showing severe dyskaryosis"
    },
    {
        key: "Possible Invasion",
        value: "with a smear showing possible invasion"
    },
    {
        key: "Unsatisfactory",
        value: "with a smear showing unsatisfactory cytology"
    },
    {
        key: "Inadequate Specimen",
        value: "Inadequate specimen"
    },
    {
        key: "Abnormal Unclassifiable",
        value: "with a smear showing abnormal unclassifiable"
    },
    {
        key: "Inflammation",
        value: "with inflammation"
    },
    {
        key: "Polyps",
        value: "with polyps"
    },
    {
        key: "IMB",
        value: "with IMB"
    },
    {
        key: "PCB (age>35 ) persist > 4 Weeks",
        value: "with PCB (age>35 ) persist > 4 Weeks"
    },
    {
        key: "Clinically Suspicious cervix",
        value: "with clinically Suspicious cervix"
    },
    {
        key: "Colp/Treatment elsewhere",
        value: "for Colp/Treatment elsewhere - no referral histology"
    },
    {
        key: "histology",
        value: "for Colp/Treatment elsewhere, with referral histology"
    }
]

export const examinationAppearanceLetterData = [
    {
        key: "AcetoWhite",
        value: "mild aceto white"
    },
    {
        key: "AcetoWhiteModerate",
        value: "moderate aceto white"
    },
    {
        key: "AcetoWhiteSevere",
        value: "severe aceto white"
    },
    {
        key: "EndocervicalCanalUpperLimitSeen",
        value: "upper limit seen"
    },
    {
        key: "StenosedOs",
        value: "a stenosed os"
    },
    {
        key: "WartyFeatures",
        value: "with warty features"
    },
    {
        key: "SquamoMetaplasia",
        value: "squamo metaplasia"
    },
    {
        key: "SuspectInvasion",
        value: "suspect invasion"
    },
    {
        key: "Atrophy",
        value: "atrophy"
    },
    {
        key: "IodineNegative",
        value: "iodine negative"
    },
    {
        key: "CondylomaWart",
        value: "condyloma/wart"
    },
    {
        key: "Ectopy",
        value: "ectopy"
    },
    {
        key: "Polyp",
        value: "polyp"
    },
    {
        key: "Cervicitis",
        value: "cervicitis"
    },
    {
        key: "HPV",
        value: "hpv"
    },
    {
        key: "MosaicCourse",
        value: "coarse mosaicism"
    },
    {
        key: "Mosaic",
        value: "fine mosaicism"
    },
    {
        key: "PunctationCourse",
        value: "coarse punctation"
    },
    {
        key: "Punctation",
        value: "fine punctation"
    },
    {
        key: "GrossAbnormality",
        value: "gross abnormality"
    }
]

export const TreatmentVaginalData = [
    "Vaginal skin on cervix", "Angles or fornices of vagina", "Vault of vagina(no cervix)", "Top third of vaginal walls", "Middle third of vaginal walls", "Bottom third of vaginal walls"
]







export const TreatmentCervicalData = [
    "Polypectomy", "Pipelle", "Fisher Cone & Diathermy", "Point diathermy excision", "D&C / EUA", "Staging procedure", "Wertheim`s hysterectomy"
]


export const ManagementOptionsLeft = [
    {
        "id": 1,
        "title": "Add / Update User Details",
        "button": "Add/Update"
    },
    {
        "id": 2,
        "title": "Change Hospital Number",
        "button": "Hospital Number"
    },
    {
        "id": 3,
        "title": "Delete Patient Details",
        "button": "Delete Patient"
    },
    {
        "id": 4,
        "title": "Delete Examination",
        "button": "Delete Exam"
    },
    {
        "id": 5,
        "title": "Update System License",
        "button": "System License"
    },
    {
        "id": 6,
        "title": "Current Active Sessions",
        "button": "Active Session"
    },
    {
        "id": 7,
        "title": "Unlock Patient Details",
        "button": "Unlock Patient"
    },
    {
        "id": 8,
        "title": "Edit Colposcopy Clinic",
        "button": "Clinic"
    }
    ,
    {
        "id": 9,
        "title": "Edit Letter Templates",
        "button": "Edit Templates",
        "path": "/dashboard/testPage"
    },
    {
        "id": 10,
        "title": "File Manager",
        "button": "File Storage",
        "path": "/file-manager"
    }
]

export const InitUserData = {
    Post: '', Title: '', ID: '',
    Name: '',
    Password: '',
    Access: '',
    SiteID: ''
}



export const NonNeoplasticGrp = [
    { title: "Koilocytosis", field: "ReferralHistologyKoilocytosis" },
    { title: "Metaplastic", field: "ReferralHistologyMetaplastic" },
    { title: "Inflammation", field: "ReferralHistologyInflammation" },
    { title: "Polyp", field: "ReferralHistologyPolyp" },
    { title: "Warty Features", field: "ReferralHistologyWartyFeatures" },
    { title: "Unsatisfactory", field: "ReferralHistologyUnsatisfactory" },
    { title: "Normal", field: "ReferralHistologyNormal" },
]

export const NeoplasticLeftGrp = [
    { title: "CIN I", field: "ReferralHistologyCIN1" },
    { title: "CIN II", field: "ReferralHistologyCIN2" },
    { title: "CIN III", field: "ReferralHistologyCIN3" },
    { title: "SCC", field: "ReferralHistologySCC" },
    { title: "Invasive", field: "ReferralHistologyInvasive" }
]

export const NeoplasticRightGrp = [
    { title: "CGIN - Possible", field: "ReferralHistologyCGINPossible" },
    { title: "CGIN - Low Grade", field: "ReferralHistologyCGINLowGrade" },
    { title: "CGIN - High Grade", field: "ReferralHistologyCGINHighGrade" },
    { title: "Adenocarcinoma", field: "ReferralHistologyAdenocarcinoma" },
    { title: "Ungraded CIN", field: "ReferralHistologyUngradedCIN" }
]


export const BSCCPHeaders = [
    {
      title: "Hospital Number",
      align: "left",
      style: {width: '100px'},
      field: "HospitalNumber"
    },
    {
      title: "NHSNumber",
      align: "center",
      field: "NHSnumber"
    },
    {
      title: "Examination Date",
      align: "center",
      field: "ExaminationDate"
    },
    {
      title: "Exam Num",
      align: "center",
      field: "ExamNum"
    },
    {
      title: "Treatment Name",
      align: "center",
      field: "TreatmentName"
    },
    {
      title: "Examiner",
      align: "center",
      field: "Examiner"
    },
    {
      title: "Trainer",
      align: "center",
      field: "Trainer"
    },
    {
      title: "Referral Centre",
      align: "center",
      field: "ReferralCentre"
    },
    {
      title: "Referral Indication",
      align: "center",
      field: "ReferralIndication"
    },
    {
      title: "Referral Smear",
      align: "center",
      field: "ReferralSmear"
    },
    {
      title: "Exam Biopsy Results Date",
      align: "center",
      field: "ExamBiopsyResultsDate"
    },
    {
      title: "Exam Non-Neoplastic Unsatisfactory",
      align: "center",
      field: "ExamNon-NeoplasticUnsatisfactory"
    },
    {
      title: "Exam CIN Count",
      align: "center",
      field: "ExamCINCount"
    },
    {
      title: "Treatment Biopsy Results Date",
      align: "center",
      field: "TreatmentBiopsyResultsDate"
    },
    {
      title: "Treatment Non-NeoPlastic Unsatisfactory",
      align: "center",
      field: "TreatmentNonNeoPlasticUnsatisfactory"
    },
    {
      title: "Treatment CIN Count",
      align: "center",
      field: "TreatmentCINCount"
    }
  ]