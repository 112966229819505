import React, {  useEffect, useRef, useState } from 'react'
import { useNavigate } from "react-router-dom";
import html2canvas from 'html2canvas';
import { Button } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { getCervixExaminationDetailsById, uploadFiles } from 'redux/actions/data.action';
import { CLEAR_EXAMINATION, CLEAR_EXAMINATION_DETAILS, OPEN_MODAL, SET_DATASHEET_PICTURES, SET_EXAMINATION_DETAILS } from 'redux/actions/types';
import ReactPlayer from 'react-player';
import { baseToFormData } from 'Utils/helpers';
import { env_vars } from 'Utils/config';



export default function ColposcopicImaging() {
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const { patientDetails,  datasheetpictures } = useSelector(({ data }) => data)
    const { examination } = useSelector(({ examination }) => examination)
    const { viewOption, edited } = useSelector(({ ui }) => ui)
    const [rnd, setRnd] = useState(null);
    const [capturedImage, setCapturedImage] = useState(null);
    const [savedImages, setSavedImages] = useState([])
    const [isPlaying, setIsPlaying] = useState(true);
    const playerRef = useRef(null);

    const handlePlayPause = () => {
        setIsPlaying((prevIsPlaying) => !prevIsPlaying);
    };

    const handleCapture = () => {
        if (playerRef.current) {
            const playerElement = playerRef.current.getInternalPlayer();
            if (playerElement) {
                const captureOptions = {
                    scale: 2, // You can experiment with different scale values
                };


                // Capture image from the video element using html2canvas
                html2canvas(playerElement, captureOptions).then((canvas) => {
                    const dataUri = canvas.toDataURL();
                    console.log('Captured image:', dataUri);
                    setCapturedImage(dataUri)
                    return baseToFormData(dataUri, `examination-picture-${datasheetpictures.length + 1}.png`);
                    // You can use the dataUri as needed, for example, display it in an <img> tag
                }).then((imgFile) => {
                    savedImages.push(imgFile);
                    setSavedImages(savedImages);
                    setRnd(Math.random())
                });
            }
        }
    };




    // navigate(`${viewOption === 'enter' ? '/dashboard/patient/colposcopicFindings' : '/dashboard/viewPatient/colposcopicFindings'}`)






    const handleSubmit = async (e) => {
        e.preventDefault();
        handleCapture()
        /*    try {
   
               const blobData = dataURItoBlob(img);
               const formData = new FormData();
               formData.append('file', blobData, 'webcam-image.png');
   
               const response = await axios.post(`${env_vars.local_api_url}/file-upload`, formData, {
                   headers: { 'Content-Type': 'multipart/form-data' },
               });
   
               let newDataSheetPictures = [];
               newDataSheetPictures = datasheetpictures;
   
               newDataSheetPictures.push({ path: response.data.path, iPictureNumber: datasheetpictures.length + 1 })
               dispatch({ type: SET_DATASHEET_PICTURES, payload: newDataSheetPictures })
   
   
           } catch (error) {
               console.error('Error uploading image:', error);
           }
    */

    };


    const handleGetExamination = (id) => {
        dispatch(getCervixExaminationDetailsById(id))
            .then(res => {
                let { tbl_DatasheetPictureNumbers } = res;
                console.log(res, 'RES EXAM DETAILS')
                dispatch({ type: SET_EXAMINATION_DETAILS, payload: { ...res, ...examination } })
                dispatch({ type: SET_DATASHEET_PICTURES, payload: tbl_DatasheetPictureNumbers })


            })
    }


    const handleNext = () => {


        navigate(`${viewOption === 'enter' ? '/dashboard/patient/colposcopicFindings' : '/dashboard/viewPatient/colposcopicFindings'}`)

    }


    const handleBack = () => {
        console.log("BACKING")
        navigate(-1)
    };



    const handleExit = (id) => {
        if (edited.find(a => a === 'examination')) {
            dispatch({ type: OPEN_MODAL, payload: "save_details" })
            // return
        } else {
            dispatch({ type: CLEAR_EXAMINATION })
            dispatch({ type: CLEAR_EXAMINATION_DETAILS })
        }


        navigate(`${viewOption === "enter" ? "/dashboard/patient" : "/dashboard/viewPatient"}`)
    }

    const handleWriteToStorage = () => {
        const formData = new FormData();
        for (const file of savedImages) {
            formData.append('files', file);
        }


        dispatch(uploadFiles(formData, examination.ExaminationID))
            .then(res => {
                handleGetExamination(examination.ExaminationID)
                console.log(res.data)
            })
            .catch(err => {
                console.log(err)
            })


    }



    useEffect(() => {
        if (patientDetails && (patientDetails.NHSnumber || patientDetails.HospitalNumber)) {


            if (examination && examination.ExaminationID) {
                handleGetExamination(examination.ExaminationID)
            }

        }



    }, [patientDetails, examination]);


    console.log(savedImages, datasheetpictures, 'SAVED IMAGES')

    return (
        <div style={{ width: '100%', display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
            <div className='col-md-8  p-2 pt-3 pb-3 '>
                {/* <WebcamImage webcamRef={webcamRef} img={img} /> */}
                <ReactPlayer
                    ref={playerRef}
                    url={`${env_vars.rtmp_url}/live/colposcopy.flv`}
                    playing={isPlaying}
                    controls
                    width="100%"
                    height="auto"
                />

            </div>
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }} className='col-md-4 p-2 pt-3 pb-3 '>
                <div className='bordered pl-2 pr-2' style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <div className='bordered pb-3 mt-3'>
                        <h3 className='card-title bordered-title'>IMAGE</h3>

                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div className='col-md-6' style={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                <label className='mt-3 text-14'>Take Image</label>
                            </div>
                            <div className='pr-2 pl-2 col-md-6'>
                                {!isPlaying ?
                                    <Button className="btn mr-3"
                                        onClick={() => handlePlayPause()}
                                    >Live</Button>
                                    :
                                    <Button className="btn mr-3"
                                        onClick={() => handlePlayPause()}
                                    >Freeze</Button>
                                }

                                {' '}
                                <Button className="btn mr-3"
                                    disabled={isPlaying}
                                    onClick={handleSubmit}
                                >Save</Button>
                            </div>
                        </div>
                        <br />
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div className='col-md-6' style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                <label className='mt-2 text-13'>Number of saved images</label>
                            </div>
                            <div className='col-md-6 pr-2 pl-2' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <input type="number" className='form-control mr-4 text-center' style={{ width: '70px' }} value={datasheetpictures.length} />
                            </div>
                        </div>
                        <br />
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <div className='pr-2 pl-2 '>
                                <Button className="btn btnIri mr-4" style={{ width: '150px' }}
                                    onClick={() => handleWriteToStorage()}
                                >Write to disk</Button>
                            </div>
                        </div>
                    </div>
                    <img
                        src={capturedImage}
                        height="100%"
                        width="100%"
                    />
                    <div className='pb-2' style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>

                        <Button type="submit" className="btn marRight20 ml-2 color-black" onClick={() => handleBack()}>Previous</Button>

                        <Button type="submit" className="btn btnIri marRight20"
                            // disabled={!(examinationDetails.name && Object.keys(errors).length === 0 ? true : false)}
                            onClick={() => handleNext()}
                        >NEXT</Button>

                        <Button className="btn btnIri marRight20"
                            onClick={() => handleExit()}
                        >EXIT</Button>

                    </div>

                </div>
            </div>
        </div >
    )
}
