import React, { useRef, useEffect, useState, useCallback } from 'react'
import BarGraph from '../BsccpBarGraph';
import { Checkbox, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { baseToFormData, createQuarterDateRanges, getDateRange, getInitials, getMonthName } from 'Utils/helpers';
import { useTheme, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { generateLetterTemplate, getStatisticsColposcopists, getStatisticsColposcopistsCases, parseFileTemplate, searchPatientDetails} from 'redux/actions/data.action';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import moment from 'moment';
import { countColposcipistsCases } from 'Utils/utils';
import StatisticsTable from '../StatisticsTable';
import { BSCCPHeaders } from 'Utils/commonData';
import { CLOSE_MODAL, OPEN_MODAL, SET_ERRORS, SET_GRID_VIEW, SET_PATIENT_DETAILS, SET_PERSONAL_REFERRAL_DETAILS, SET_SELECTED_ROW, SET_VIEW_OPTION } from 'redux/actions/types';
import { useReactToPrint } from 'react-to-print';
import '../../../../css/statistics.css';
import { utils, writeFile } from 'xlsx';


const headers = BSCCPHeaders;


const objectWithKeys = {};
headers.forEach(str => {
  objectWithKeys[str.field] = "-";
});


const Bsccp = () => {
  const dispatch = useDispatch();
  const {user } = useSelector(({auth}) => auth)
  const {isGridView, selectedRow} = useSelector(({ui}) => ui)
  const navigate = useNavigate();
  const theme = useTheme();

  const [colposcopists, setColposcopists] = useState([]);
  const casesData = [
    {
      key: "categoryCount1",
      label: 'First Exams',
      backgroundColor: 'rgba(255, 0, 0, 1)',
      borderColor: 'rgba(255, 0, 0, 1)',
      borderWidth: 1,
      data: [65, 59, 80, 81, 56, 55],
    },
    {
      key: "categoryCount2",
      label: 'Follow-up Visits',
      backgroundColor: 'rgba(0, 128, 0, 1)',
      borderColor: 'rgba(0, 128, 0, 1)',
      borderWidth: 1,
      data: [28, 48, 40, 19, 86, 27],
    },
    {
      key: "categoryCount3",
      label: 'Treatment',
      backgroundColor: 'rgba(0, 0, 255, 1)',
      borderColor: 'rgba(0, 0, 255, 1)',
      borderWidth: 1,
      data: [38, 88, 97, 68, 45, 30],
    },
    {
      key: "categoryCount4",
      label: 'Trainings',
      backgroundColor: 'rgba(255, 255, 0, 1)',
      borderColor: 'rgba(255, 255, 0, 1)',
      borderWidth: 1,
      data: [15, 40, 35, 20, 60, 45],
    }
  ];
  const [selectedColp, setSelectedColp] = useState([]);
  const [cases, setCases] = useState([]);
  const [rows, setRows] = useState(new Array(10).fill(objectWithKeys));
  const [loading, setLoading] = useState(false);
  const [rnd, setRnd] = useState(0);
  const [isRange, setIsRange] = useState(false);
  const [range, setRange] = useState({
      start: new Date('2020-01-01'),
      end: new Date('2022-01-01')
      
    })
  const [values, setValues] = useState({
    period: null,
    colposcopist: "All",
});
const [graphData, setGraphData] = useState({
  rnd: 0,
  labels: [],
  datasets: casesData,
})

const componentRef = useRef();
let strtYr = new Date(range.start).getFullYear()
let endYr = new Date(range.end).getFullYear()
let strtDate = getMonthName(new Date(range.start).getMonth() + 1);
let endDate = getMonthName(new Date(range.end).getMonth() + 1);

let titleCaption = strtYr == endYr ? strtDate == endDate ? `${strtDate} ${strtYr}` : `${strtDate} - ${endDate} ${strtYr}` : `Year ${strtDate} ${strtYr} - ${endDate} ${endYr}`
  



  const handlePlot = useReactToPrint({
   content: () => componentRef.current,
   documentTitle: 'Visitor Pass',
   onAfterPrint: () => console.log('Printed PDF successfully!'),
  });

const handleClick = prop => event => {
  let selected = rows[event]
  dispatch({ type: SET_VIEW_OPTION, payload: 'review' })
  if(prop == 'click'){
  dispatch({type: SET_SELECTED_ROW, payload: event})
  } else {
    handleSubmit({HospitalNumber: selected.HospitalNumber})
  }
  // setRnd(Math.random())
}


const handleSubmit = (vals) => {
  dispatch(searchPatientDetails(vals))
      .then(res => {
          if (res && res.length !== 0) {
              setValues({
                  NHSnumber: '',
                  HospitalNumber: ''
              })
              if (res.length === 1) {
                  //Check if inuseby
                  dispatch({ type: SET_PATIENT_DETAILS, payload: res[0] })
                  if (res[0].tbl_PersonalReferralDetails) {
                      dispatch({ type: SET_PERSONAL_REFERRAL_DETAILS, payload: res[0].tbl_PersonalReferralDetails[res[0].tbl_PersonalReferralDetails.length - 1] })
                  }
                  navigate('/dashboard/viewPatient')
              } 
              dispatch({ type: CLOSE_MODAL});
              // dispatch({type: SET_SEARCH_STRING, payload: searchStr})
          }
      })
}



  const handleSelectPeriod = (e) => {
    let ind = createQuarterDateRanges().indexOf(e);
    let startDate = range.start;
    let endDate = range.end;
    if(!e) {
      startDate = "01/01/2020"
      endDate = "01/01/2022"
      setRange({...range, start: startDate, end: endDate})  

    } else { 
    if(ind !== -1){
      let ranges = getDateRange(ind);
      setValues({...values, period: e})
      setRange({start: ranges[0], end: ranges[1]});
      startDate = ranges[0];
      endDate = ranges[1];
    } else {
      if(e.field == 'start'){
        startDate = e.value;
      }
      if(e.field == 'end'){
        endDate = e.value
      }
      
      setRange({...range, [e.field]: e.value})  
    }
    }
    handleGetColposcopistsCases(startDate, endDate)
  }

  const handleSelectColposcopist = (e) => {
    dispatch({type: SET_SELECTED_ROW, payload: null})
   let colps = e == 'All' ? colposcopists : colposcopists.filter(a => a.iUserID == e);
   setSelectedColp(colps)
    getGraphData(cases, colps)
    setValues({...values, colposcopist: e})
    setRnd(Math.random())
  }
  
  


  const handleGetColposcopists = () => {
        dispatch(getStatisticsColposcopists(user.SiteID, 'Colposcopy'))
        .then(res => {
          if(res){
              setColposcopists(res)
              setSelectedColp(res)
              setValues({...values, colposcopist: "All"})
          }
        })
  }

  const handleGetColposcopistsCases = (start, end) => {
    dispatch(getStatisticsColposcopistsCases(user.SiteID, start, end))
    .then(res => {
    if(res){
      setCases(res)
      getGraphData(res, selectedColp)
    }
    })
}

const handleGridViewSave = useCallback(() => {
  /* generate worksheet from state */
  let newRows = rows.map(row => {
      let newObj = {};
      
      headers.forEach(item => {
          newObj[item.title] = row[item.field];
      })
      
      return newObj;
  })
    
  // var ws =  utils.aoa_to_sheet([[]]);

  const ws = utils.json_to_sheet(newRows, { skipHeader: false, origin: "A2"});
  // var ws = utils.aoa_to_sheet([["Bsccp"]],  {origin: "A1"});
    
  /* create workbook and append worksheet */
  const wb = utils.book_new();
  utils.book_append_sheet(wb, ws, "Data");
  /* export to XLSX */
  writeFile(wb, `bsccp_${moment().format('MMDDYYYY')}.xlsx`);
}, [rows]);

const handleBsccpPrint = async () => {
  let newColps = colposcopists.map(a => {
    return { surname: String(a.Name).split(' ')[1], initials: String(a.Name)[0], title: a.Title }
  })
  setLoading(true)


 
  let response = await dispatch(generateLetterTemplate({
  letterTemplate: "Bsccp-print.docx",
	extend: "pdf",
	data: {
	 "period": titleCaption,
	 "items": newColps
	}
  }))
  .then((res) => {
    setLoading(false)
    return res
  })
    let { fileUrl} = response.data;
  
  window.open(fileUrl, '_blank')


}


const handlePrint = async () => {
  // setUrlContent(null);
  setLoading(true)


 
  let response = await dispatch(generateLetterTemplate({
  letterTemplate: "Bsccp_01.docx",
	extend: "pdf",
	data: {
	 "items": rows
	}
  }))
  .then((res) => {
    setLoading(false)
    return res
  })
    let { fileUrl} = response.data;
  
  window.open(fileUrl, '_blank')


}


const getGraphData = async (selCases, selColp) => {
 let dataCases = await countColposcipistsCases(selCases, selColp);
 let datasets = [];
//  if(selColp.length === 1){
 datasets = casesData.map(a => {
        let newObj = a;
        if(dataCases[a.key]){
        
          if(selColp.length == 1){
            let ind = colposcopists.map(a => Number(a.iUserID)).indexOf(Number(selColp[0].iUserID));
            newObj.data = [dataCases[a.key][ind]]  
          } else {
            newObj.data = dataCases[a.key]  
          }
        }
        return newObj
    })
    
 
 
 setGraphData({
    labels: selColp.map(a => getInitials(a.Name)),
    datasets: datasets,
    rnd: Math.random()
 })
 
 

 setRows(dataCases.colposcopistCases)
 setRnd(Math.random())
}


  const handleExit = () => {
    if(isGridView){
      dispatch({type: SET_GRID_VIEW, payload: false})
      dispatch({type: SET_SELECTED_ROW, payload: null})
    } else {
      navigate('/dashboard/statistics')
    }
  }

  useEffect(() => {
    if(user.SiteID){
      handleGetColposcopists();
    }
  }, [user.SiteID])


  useEffect(() => {
      handleSelectPeriod(createQuarterDateRanges()[0])
      setRnd(Math.random())
  }, [colposcopists])


    return (
        <div
        ref={componentRef}
        >
        <div className="card-header bg-iri">
        <div
            style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                // justifyContent: 'space-between',
                // alignItems: 'flex-end'
            }}
        >
            <h4 className="text-white">Colposcopists Cases - {titleCaption}</h4>
        </div>
    </div>
    <div className="card-body statistics-table"
    >
    
  
    {isGridView ?
    <>
      <StatisticsTable rows={rows} headers={headers} selectedRow={selectedRow} onClick={handleClick('click')} onDblClick={handleClick('dblClick')}/>  
      <div style={{width: '100%'}}>
      <Typography variant="body1" className='ml-5 mt-1' style={{ fontWeight: 'bold', textAlign: 'left'}}>Total Records: {rows.length}</Typography>
    </div>
      </>
    :    
    <BarGraph data={graphData} />
  }
 
  <br/>
    <div
    className='bsccpActions'
    >
    <Grid container spacing={3}
    direction="row"
    justifyContent="flex-end"
    alignItems="flex-end"
  >
  {isGridView ?
    <Grid item lg={6} xs={12}>
    <Grid 
    container
    spacing={2}
    >

        <Grid item lg={3}
        >
        <button className="btn btnIri"
        disabled={selectedRow == null}
        onClick={() => {
          let selected = rows[selectedRow]
              dispatch({ type: SET_VIEW_OPTION, payload: 'review' })
              handleSubmit({HospitalNumber: selected.HospitalNumber})
        }}
        style={{minWidth: '75px'}}
        >Review</button>
        </Grid>
        <Grid item lg={3}>
        <button className="btn btnIri"
        disabled={loading}
        onClick={() => handleGridViewSave()}
        style={{minWidth: '75px'}}
        >Save</button>
        </Grid>
        <Grid item lg={3}>
        <button className="btn btnIri"
        disabled={loading}
        style={{minWidth: '75px'}}
        onClick={() => handlePrint()}
        >Print</button>
        </Grid>
        <Grid item lg={3}>
        <button className="btn btnIri"
        style={{minWidth: '75px'}}
        onClick={() => handleExit()}
        >Exit</button>
       </Grid>
    </Grid>    
    </Grid>

  :
  <>
      <Grid item lg={6} xs={12}
      direction="row"

      justifyContent="flex-end"
      alignItems="flex-end"
      >
      <Grid 
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      spacing={2}
      >
      <Grid item lg={3} xs={12}
      >
  
      <div
      style={{width: '100%', display: 'flex',flexDirection: 'column',  alignItems: 'flex-start', justifyContent: 'flex-end'}}
      >
      <FormControlLabel
      control={<Checkbox color='primary'
      sx={{
        color: theme.palette.grey[400],
        '&.Mui-checked': {
          color: theme.palette.primary.main,
        },
      }}
      checked={isRange}
      value={!isRange}
      onChange={(e) => {
        setIsRange(e.target.value == 'true' ? true : false)
        if(isRange){
          handleSelectPeriod(createQuarterDateRanges()[0])
        } else {
          handleSelectPeriod(false)
        }
        setRnd(Math.random())
        }}
      className='text-blue' style={{fontWeight: 'bold'}}
      />} label="Date Range" />
      </div>
      
      </Grid>
      <Grid item lg={2} xs={2} 
          style={{textAlign: 'right'}}
          display="flex"
          height="100%"
          justifyContent="flex-end"
          alignItems="flex-end"
      >
      
      <label className="control-label text-blue" style={{fontWeight: 'bold'}}>Period</label>

      </Grid>
      <Grid item lg={6} xs={10}>
{isRange ? 
  <FormGroup 
  style={{display: 'flex'}}
  >
                 
                          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                          <Label>
                          Start
                        </Label>
                     <Input
                            type="date"
                            id="startdate" name="startdate" className="form-control" placeholder=""
                            value={range.start ? moment(range.start).format('YYYY-MM-DD') : ''}
                            onChange={(e) => handleSelectPeriod({value: e.target.value, field: 'start'})}
                          />
                          </div>
                          
                          &nbsp;&nbsp;
                          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                          <Label>
                          End
                        </Label>
                          <Input
                          type="date"
                          id="endDate" name="endDate" className="form-control" placeholder=""
                          value={range.end ? moment(range.end).format('YYYY-MM-DD') : ''}
                          onChange={(e) => handleSelectPeriod({value: e.target.value, field: 'end'})}

                        />
                          </div>
                        
                        </FormGroup>

: 
<>

<select name="marital-Status" id="marital-Status" className="form-control"
value={values.period}
onChange={(e) => handleSelectPeriod(e.target.value)}

>
<option value={null}>Select</option>
{createQuarterDateRanges().length !== 0 && createQuarterDateRanges().map((a, index) => {
  return (
    <option value={a}>{a}</option>
  )
})}
</select>
</>

}
      </Grid>
      </Grid>
      <Grid 
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      spacing={2}
      className='mt-1'
      
      >
      <Grid item lg={6} xs={3} 
          style={{textAlign: 'right'}}
      >
      <label className="control-label text-blue" style={{fontWeight: 'bold'}}>Colposcopist</label>

      </Grid>
      <Grid item lg={6} xs={9}>
  <select name="marital-Status" id="marital-Status" className="form-control"
    value={values.colposcopist}
    onChange={(e) => handleSelectColposcopist(e.target.value)}

  >
    <option value="All">All Colposcopists</option>
    {colposcopists.length !== 0 && colposcopists.map(a => {
      return (
        <option value={a.iUserID}>{a.Name}</option>
      )
    })}
  </select>
      </Grid>
      </Grid>
      
      </Grid>
 
 
      <Grid item lg={5} xs={12}
      direction="row"
      justifyContent="flex-end"
      alignItems="flex-end"
      >
      <Grid 
      container
      spacing={1}
      >
          <Grid item lg={3}
          >
          <button className="btn btnIri"
          onClick={() => {
              dispatch({type: SET_GRID_VIEW, payload: !isGridView})
          }}
          style={{minWidth: '75px'}}
          >View Grid</button>
          </Grid>
          <Grid item lg={3}>
          <button className="btn btnIri"
          onClick={() => handlePlot()}
          style={{minWidth: '75px'}}
          >Plot</button>
          </Grid>
          <Grid item lg={3}>
          <button className="btn btnIri"
          disabled={loading}
          onClick={() => handleBsccpPrint()}
          style={{minWidth: '75px'}}
          >Print</button>
          </Grid>
          <Grid item lg={3}>
          <button className="btn btnIri"
          style={{minWidth: '75px'}}
          onClick={() => handleExit()}
          >Exit</button>
      </Grid>
    </Grid>
      </Grid>
      </>
  }
      
  </Grid>
  </div>
  
    </div>
        </div>
    )
}

export default Bsccp;
