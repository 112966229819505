import ReactDOM from 'react-dom';
import themes from 'devextreme/ui/themes';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './redux/store';

const root = ReactDOM.createRoot(document.getElementById("app"));

themes.initialized(() =>
  root.render(
    <Provider store={store}>
      <App />
    </Provider>
  )
);